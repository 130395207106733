
import { Component, Vue, Watch } from 'vue-property-decorator';
/* COMPONENTS */
import SvgIcon from '@/components/utilities/SvgIcon.vue';
import Header from '@/modules/landingPage/components/eu/layout/Header.vue';
import Footer from '@/modules/landingPage/components/eu/layout/Footer.vue';
import Calculator from '@/modules/remittance/components/Calculator.vue';
import Toast from '@/components/utilities/Toast.vue';
import ContactWhatsappModal from '@/components/utilities/ContactWhatsappModal.vue';
/* INTERFACES */
import { CountryCurrency } from '@/modules/countries/interfaces/countries.interface';
import { FullRate, Rate } from '@/modules/rates/interfaces/rate.interface';
import { RateType } from '@/modules/rates/interfaces/rateType.interface';
import { RangeRate } from '@/modules/rates/interfaces/rangeRate.interface';
/* STORE */
import { auth, countries, rates } from '@/store/namespaces';
import CountryMethods from '@/store/countries/methods/countries.methods'
import RatesMethods from '@/store/rates/methods/rates.methods'
import AuthMethods from '@/store/auth/methods/auth.methods';
import { currencyConvertion, findDefaultRate, findDefaultRateType, findSpecificRate, validateRemittanceRate, validateRemittanceRateFromDestiny } from '@/modules/rates/functions/rates.functions';
import { RATE_CATEGORIES } from '@/modules/rates/constants/rate.constants';
/* FUNCTIONS */
import {emailRepository} from '@/http/email/email.repository';
import { showToast } from '@/utils/global-functions';
@Component({
  components: {
      SvgIcon,
      Header,
      Footer,
      Calculator,
      Toast,
      ContactWhatsappModal
  },
})
export default class Home extends Vue {
   /* CALCULATOR */
    currentOriginCountryCurrency: CountryCurrency = {iso_cod: '',id_country: null,id_currency: null,name_country: '',country_iso_code: '',viewing_name: ''}
    currentDestinyCountryCurrency: CountryCurrency = {iso_cod: '',id_country: null,id_currency: null,name_country: '',country_iso_code: '',viewing_name: ''}
    rateValue  : Rate = null;
    originAmount = 0;
    destinyAmount = 0;
    rateInfo : {rateType: RateType | null, rangeRate: RangeRate | null} = {rateType: null, rangeRate: null}
    rateType: RateType = null;
    calcLoader: boolean = true;
    rateValueName = ''
    /* END CALCULATOR */
    /*OTHERS */
    servicesList=[
        {
            title: 'Sistema de Auto-Servicio',
            text: 'Realiza tus envíos de forma rápida y automatizada.'
        },
        {
            title: 'Sistema de Atención Asistida',
            text: 'Realiza tus envíos atendido por nuestros Asesores.'
        },
        {
            title: 'Historiales y Estadísticas',
            text: 'Controla lo que envías y lleva registro de todo.'
        },
        {
            title: 'Tasas Mejoradas',
            text: 'Recibe descuentos y tasas mejoradas.'
        },
        {
            title: 'Programa de Recompensas',
            text: 'Invita a tus amigos, recibe beneficios y participa en sorteos.'
        },
        
    ]
    countries=[
        {
            image: 'ar',
            name: 'Argentina',
            phoneNumber: '+58 414 838 398'
        },
        {
            image: 'br',
            name: 'Brasil',
            phoneNumber: '+58 414 838 398'
        },
        {
            image: 'cl',
            name: 'Chile',
            phoneNumber: '+58 414 838 398'
        },
        {
            image: 'co',
            name: 'Colombia',
            phoneNumber: '+58 414 838 398'
        },
        // {
        //     image: 'us',
        //     name: 'Estados Unidos',
        //     phoneNumber: '+58 414 838 398'
        // },
        {
            image: 'pe',
            name: 'Perú',
            phoneNumber: '+58 414 838 398'
        },
        {
            image: 'do',
            name: 'República Dominicana',
            phoneNumber: '+58 414 838 398'
        },
        {
            image: 've',
            name: 'Venezuela',
            phoneNumber: '-'
        },
        // {
        //     image: 'eu',
        //     name: 'Europa',
        //     phoneNumber: '+58 414 838 398'
        // },
        // {
        //     image: 've',
        //     name: 'Venezuela',
        //     phoneNumber: '+58 414 838 398'
        // }
    ]
    ourClients=[
        {
            image: 'client1',
            text: 'Ya llego dos años con ustedes de satisfacción. Muchísimas gracias, que Dios os siga bendiciendo para que su trabajo siga fluyendo y generando muchas cosas buenas.',
            client: 'Yurelys del Valle - Perú'
        },
        {
            image: 'client2',
            text: 'Me encant que sean tan cariñosos como si fueramos amigos, son los mejores. Además de buenos y eficientes.',
            client: 'Anacel Caridad - Chile'
        } 
    ]
    ourClientsShort=[
        {
            image: 'client3',
            text: 'Siempre los he preferido y los recomiendo con todo el que sepa que necesita realizar envío. Los recomiendo con los ojos cerrados.',
            client: 'Luis Eduardo - Brasil'
        },
        // {
        //     image: 'client4',
        //     text: 'Son excelentes, de verdad. Ustedes son una de las mejores decisiones, uno envía y está tranquilo que el dinero llegará pronto y con excelente tasa.',
        //     client: 'Anacel Caridad - Chile'
        // },
        {
            image: 'client5',
            text: 'Enviar remesas con ustedes es sentirse tranquilo que le dinero llegará, además siempre son amables.',
            client: 'Carlos Andres - Argentina'
        }
    ]
    /* NEWSLETTER */
    newsletter={
        email: '',
        fullName: '',
        termsAndConditions: false
    }
    newsletterLoader= false;
    showContactModal= false;
    writingInOriginValGlobal = true;
    
    async mounted(){
        try{
            if(!await this.fetchCountriesCurrencies() ||
                !await this.fetchRateTypes() ||
                !await this.fetchRangeRates() )
                throw new Error()
            else{
                this.currentDestinyCountryCurrency = this.destinyCountriesCurrencies.find(el=> el.country_iso_code === 'VE')
                if (process.env.VUE_APP_EUROPE_VERSION){
                    this.currentOriginCountryCurrency = this.originCountriesCurrencies.filter(el => el.iso_cod === 'EUR')[0]
                }
                else this.currentOriginCountryCurrency = this.originCountriesCurrencies[0]
                if (!await this.fetchFullRates({id_origin_country: this.currentOriginCountryCurrency.id_country,id_origin_currency: this.currentOriginCountryCurrency.id_currency,id_destiny_country: this.currentDestinyCountryCurrency.id_country,id_destiny_currency: this.currentDestinyCountryCurrency.id_currency,email_user: null}))
                    throw new Error();
                else {
                    this.setCalculatorRate();
                    this.calcLoader = false;
                }
            }
        }
        catch(e){
            this.$router.push({name:'ErrorPage'}).catch();
        }
    }

    goToSignUp(){
        if (process.env.VUE_APP_LOGIN_DISABLED){
            this.showContactModal = true;
        }
        else{
            if (this.isAuthenticated)
                this.$router.push({name:'Dashboard'}).catch();
            else
             this.$router.push({name:'CreateAccount'}).catch()
        }
    }

    changeShowContactModal(newVal: boolean){
      this.showContactModal = newVal;
    }

    /* CALCULATOR */
    @Watch('originAmount')
    originAmountChanged(){
        this.setCalculatorRate()
    }
    
    updateWritingInOriginVal(newVal: boolean) {
        this.writingInOriginValGlobal = newVal;
    }

    setCalculatorRate(){
        if (this.writingInOriginValGlobal)
                this.rateInfo = validateRemittanceRate(this.currentOriginCountryCurrency.id_currency,this.currentDestinyCountryCurrency.id_currency,this.rateTypes,this.rangeRates,this.originAmount)
            else this.rateInfo = validateRemittanceRateFromDestiny(this.currentOriginCountryCurrency.id_currency,this.currentDestinyCountryCurrency.id_currency,this.rateTypes,this.rangeRates,this.destinyAmount,this.userRates.rates)
        this.rateType = this.rateInfo.rateType;
        if (!this.rateType) this.rateType = findDefaultRateType(this.rateTypes);
        if (this.rateType) this.rateValue = findSpecificRate(RATE_CATEGORIES.MANUAL, this.fullRates.manualRates,this.rateType.idRateType);
        else this.rateValue = findDefaultRate(RATE_CATEGORIES.MANUAL, this.fullRates.manualRates)
       this.rateValueName = this.rateValue.rate_type_name;
    }
    async selectDestinyCurrencyCalculator(countryCurrency: CountryCurrency){
        this.calcLoader = true;
        this.currentDestinyCountryCurrency = countryCurrency;
        if(!await this.fetchFullRates({id_origin_country: this.currentOriginCountryCurrency.id_country,id_origin_currency: this.currentOriginCountryCurrency.id_currency,id_destiny_country: this.currentDestinyCountryCurrency.id_country,id_destiny_currency: this.currentDestinyCountryCurrency.id_currency,email_user: null}))
            this.$router.push({name: 'ErrorPage'}).catch();
        this.setCalculatorRate();
        this.refreshAmounts();
        this.calcLoader = false;
    }
    refreshAmounts(){
        this.originAmount=0;
        this.destinyAmount=0;
    }
    async selectOriginCurrencyCalculator(countryCurrency: CountryCurrency, refresh: boolean){
        this.calcLoader = true;
        this.currentOriginCountryCurrency = countryCurrency;
        if (refresh){
            if(!await this.fetchFullRates({id_origin_country: this.currentOriginCountryCurrency.id_country,id_origin_currency: this.currentOriginCountryCurrency.id_currency,id_destiny_country: this.currentDestinyCountryCurrency.id_country,id_destiny_currency: this.currentDestinyCountryCurrency.id_currency,email_user: null}))
                this.$router.push({name: 'ErrorPage'}).catch()
            this.setCalculatorRate();
        }
        this.refreshAmounts();
        this.calcLoader = false;
    }
    get rateConvertion(){
        let convertion = currencyConvertion(this.rateValue,10)
        return this.$options.filters.currency(convertion)
    }
    /* END CALCULATOR */
    async sendNewsletterEmail(){
        try {
            if (this.newsletter.fullName.length === 0 || this.newsletter.email.length === 0) showToast('Debe completar los datos del formulario','error')
            else if (!this.newsletter.termsAndConditions) showToast('Debe aceptar los términos y condiciones','error')
            else {
                this.newsletterLoader=true;
                let response = await emailRepository.sendMail(
                    {
                        "subject": "Suscripción al boletín",
                        "title": "Un nuevo usuario ha solicidato la suscripción al boletín",
                        "subtitle": "",
                        "firstParagraph": `Nombre: ${this.newsletter.fullName}`,
                        "secondParagraph": `Correo: ${this.newsletter.email}`,
                        "from": "no-reply@bithonor.com",
                        "to": "hola@bithonor.com"
                    }
                )
                this.newsletterLoader=false;
                if (response === "Email succesfully sent") {
                    showToast('Su suscripción al boletín ha sido enviada','success');
                    this.newsletter={fullName:'',email:'',termsAndConditions:false}
                }
                else showToast('Ha ocurrido un error al enviar su suscripción','error')
            }
        } catch(e) {
            this.newsletterLoader=false;
            showToast('Ha ocurrido un error al enviar su suscripción','error')
        }
    }
    /* COUNTRIES */
    @countries.Getter(CountryMethods.getters.GET_DESTINY_COUNTRY_CURRENCY)
    destinyCountriesCurrencies!: CountryCurrency[];
    @countries.Getter(CountryMethods.getters.GET_ORIGIN_COUNTRY_CURRENCY)
    originCountriesCurrencies!: CountryCurrency[];
    @countries.Action(CountryMethods.actions.FETCH_ORIGN_DEST_COUNTRY_CURRENCY)
    fetchCountriesCurrencies!: () => Promise<boolean>
    /* RATES */
    @rates.Getter(RatesMethods.getters.GET_RANGE_RATES)
    rangeRates!: RangeRate[];
    @rates.Getter(RatesMethods.getters.GET_RATE_TYPES)
    rateTypes!: RateType[];
    @rates.Action(RatesMethods.actions.FETCH_RATE_TYPES)
    fetchRateTypes!:()=>Promise<boolean>
    @rates.Action(RatesMethods.actions.FETCH_RANGE_RATES)
    fetchRangeRates!:()=>Promise<boolean>
    @rates.Action(RatesMethods.actions.FETCH_FULL_RATES)
    fetchFullRates!: (data: any) => Promise<boolean>
    @rates.Getter(RatesMethods.getters.GET_FULL_RATES)
    fullRates!: FullRate;
    /* AUTH */
    @auth.Getter(AuthMethods.getters.GET_AUTHENTICATION)
    isAuthenticated!: boolean;
}
